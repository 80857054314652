import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"

const imageFarroSalad = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/farrosalad_large.jpg';
const RecipePage = () => (
    <Layout metaTitle="Bacon Farro Salad with Bourbon Vinaigrette"
        metaDescription="We don't know about you, but if we're making a salad it needs to pull its weight. In other words: bring some bacon! Try our hazelnut farro salad recipe today!"
        metaKeywords="farro salad, bacon vinaigrette"
    >

<meta property="og:image" content={imageFarroSalad} />
        <link rel="image_src" href={imageFarroSalad} />
        <div id="recipe-details-wrapper" >
            <div class="image">
                <img src={imageFarroSalad} class='img-responsive' alt='Bacon Hazelnut Farro Salad with Bourbon Bacon Vinaigrette' />
            </div>

            <div class="inner-wrapper">
                <div id="recipe-details">
                    <div class="container">
                        <div class="content-wrapper">
                            <h1>Bacon Hazelnut Farro Salad with Bourbon Bacon Vinaigrette</h1>

                            <div class="recipe-content">
                                <p >We don't know about you, but if we&rsquo;re making a salad to complement a meal it needs to pull its weight. In other words: flavorless greens and dressings need not apply. </p>
                                <p>Around here we start our salads with farro, a soft grain with a delicious nutty flavor that's sure to get more than a few approving nods. Then, we toss in toasted hazelnuts and crispy Applewood Smoked Bacon to balance out the farro's tenderness with a satisfying crunch.&nbsp; The end result is an indulgent, celebratory taste that avoids &ldquo;overbearing&rdquo; with the addition of bright, acidic cider vinegar and herbs for freshness. </p>
                                <p>Side salad? Nah, you&rsquo;ll never want to put this one to the side.</p>
                            </div>
                        </div>

                        <div class="timing row">
                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/prepIcon.png" alt="Prep Time" />
                                </div>
                                <div class="details">
                                    <h4>Prep Time:</h4>
                                    <p>
                                        35&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/cookIcon.png" alt="Cook Time" />
                                </div>
                                <div class="details">
                                    <h4>Cook Time:</h4>
                                    <p>
                                        1&nbsp;hour                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/serveIcon.png" alt="Serving Size" />
                                </div>
                                <div class="details">
                                    <h4>Serving Size:</h4>
                                    <p>5-6 bowls</p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div class="ingredients-wrapper recipe-section">
                    <div class="container">


                        <h2>Ingredients</h2>
                        <div class="row ingredients">
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                               12 <span class="uom">Strips</span> Wright&#174; Brand Applewood  Smoked  Bacon, cut into &frac12; strips
                               
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                6 <span class="uom">Cups</span> water
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 <span class="uom">Teaspoon</span> salt
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 <span class="uom">Cup</span> hazelnuts, peeled, toasted and roughly chopped
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                               2 <span class="uom">Cups</span> farro, rinsed
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 <span class="uom"></span> orange, zested, then juiced
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 <span class="uom">Cup</span> golden raisins, roughly chopped
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                &#8531; <span class="uom">Cup</span> shallot, small diced
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                &frac14; <span class="uom">Cup</span> cup scallion greens, thinly sliced on bias
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                2 <span class="uom">Tablespoons</span> parsley, finely chopped
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 &frac14; <span class="uom"></span> thyme, stripped from branch and finely chopped
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                &frac12; <span class="uom">Teaspoon</span> sage, finely chopped
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                6 <span class="uom">Ounces</span> bourbon – get the good stuff
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                3 <span class="uom">Ounces</span> cider vinegar
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                <span class="uom"></span> salt and finely ground pepper to taste
                </div>
                        </div>


                    </div>
                </div>

                <div class="instructions-wrapper recipe-section">
                    <div class="container">


                        <h2>Directions</h2>
                        <div class="instructions">
                            <p>
                                1. First combine water and salt in a 2 qt. pot. Stir it up to distribute the salt and add your rinsed farro.
                </p>
                            <p>
                                2. Next cover the farro pot, and bring it to a boil over medium high heat.  Then reduce the heat to a simmer and cook for 30 minutes, or until tender. Keep in mind farro will have a good amount of chewiness when cooked right, it should never be hard.
                </p>
                            <p>
                                3. When fully cooked, pour the mixture through a fine mesh strainer to drain off the excess liquid.  Discard the liquid then spread the farro on a cookie sheet and leave it out to cool.
                </p>
                            <p>
                                4. Next place the bacon in a skillet over medium heat. Cook it uncovered until the fat has rendered and the bacon a crispy brown. Pour the liquid fat and crisp bacon through a strainer into a medium bowl.  Reserve both the cooked bacon and the rendered fat, you’ll need it for later.
                </p>
                            <p>
                                5. Now pour your (top-shelf) bourbon into the bacon pan and use a wooden spoon, rubber spatula, or flat whisk to scrape up the browned bits on the bottom of the pan, Then place over medium low heat.  Now we can’t stress this next part enough. Alcohol is flammable, so be aware: WHEN BOURBON COMES TO A BOIL ON YOUR GAS STOVE OR COOKTOP, IT WILL CATCH FIRE – USE EXTREME CAUTION IN THIS STEP.  The flames should not be large and when they subside the bourbon is done cooking.  Pour the (fire-tested) reduced bourbon from the pan and reserve it for later.
                </p>
                            <p>
                                6. Now it’s time to assemble the bourbon-bacon vinaigrette:
            In a medium mixing bowl, add 3 oz of  your reserved bacon fat. Whisk it in the reduced bourbon, cider vinegar, and juice from the orange, then season with salt and pepper to taste

                </p>
                            <p>
                                7. And finally …  fix up the salad:
            In a large mixing bowl, combine cooled, cooked farro, crisped bacon, hazelnuts, golden raisins, scallions, shallots, parsley, thyme, sage, and orange zest (reserve a fat pinch of each - scallions, parsley, and thyme to sprinkle over the salad after plating). Add 4 oz vinaigrette and stir well to get a nice distribution of ingredients and dressing.

                </p>
                            <p>
                                8. Chef’s Notes:
            Your salad and vinaigrette can be assembled one day in advance and refrigerated. Just be sure to remove from the refrigerator one hour before serving it up. You should dress salad just before serving.
            If you’re having problems finding faro, substitute wild rice, brown rice, or quinoa.  Just cook it as directed on the package.
            Wait … you can’t find hazelnuts either? No problem, just toast whole almonds or pecans instead.

                </p>
                        </div>


                        <div></div>
                    </div>
                </div>
            </div>
        </div>


        <article class="locator-callout">
            <div class="quick-form-container">


                <div class="product-locator-search">
                    <h2>Find Our Products</h2>
                    <form data-url="/locator/" action="/locator/" method="get">
                        <input Value="ANY_ANY" id="PROD" name="PROD" type="hidden" value="ANY_ANY" />
                        <div class="input-wrapper">
                            <input data-val="true" data-val-regex="Please enter a 5-digit ZIP code" data-val-regex-pattern="^\d{5}" data-val-required="Please enter a 5-digit ZIP code" id="ZIP" maxLength="5" name="ZIP" placeholder="Enter Zip" type="tel" defaultValue="" />
                            <input type="submit" value="Locate Bacon"></input>
                            <span class="field-validation-valid" data-valmsg-for="ZIP" data-valmsg-replace="true"></span>
                        </div>
                    </form>
                </div>

            </div>
        </article>
    </Layout>
)

export default RecipePage
